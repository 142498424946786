import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiSwitch :{
      colorSecondary:{
      "& .Mui-checked": {
        color: "#FA9131 !important",
      },
      },
    },
    MuiFilledInput: {
      underline: {
        "&::after": {
          left: "0", 
          right: "0", 
          bottom: "0", 
          content: "\"\\00a0\"", 
          position: "absolute",
           transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms", 
           borderBottom: "none !important", pointerEvents: "none",
        },
        "&::before": {
          left: "0", 
          right: "0", 
          bottom: "0", 
          content: "\"\\00a0\"", 
          position: "absolute",
           transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms", 
           borderBottom: "none !important", pointerEvents: "none",
        },
      },
    },
    // MuiAvatar: {
    //   root: {
    //     width:"30px !important",
    //     height: "30px !important"
    //   }
    // },
    MuiAccordionDetails: {
      root: {
        display: "block !important",
      },
    },
    MuiOutlinedInput: {
      inputAdornedStart: {
        padding: "4px !important",
      },
      inputMultiline: {
        padding: "1px !important",
      },
    },
    MuiPaginationItem: {
      rounded: {

        "& .Mui-selected": {
          backgroundColor: "green !important",
        },
      },
      page: {
        "& .Mui-selected": {
          backgroundColor: "green !important",
        },
      },
    },
    MuiTableRow: {
      // root: {
      //   background: "linear-gradient(180deg, #424344 0%, #232427 100%)", 
      //   borderRadius: "4px",
      // },
    },
    // MuiAccordionDetails: {
    //   root: {
    //     display: "block !important",
    //     padding: "8px 16px 8px",
    //   },
    // },
    // MuiAccordion: {
    //   root: {
    //     "&::before": {
    //       top: "-1px",
    //       left: "0",
    //       right: "0",
    //       height: "1px",
    //       //  content: "\"\"", 
    //       opacity: "1",
    //       position: "absolute",
    //       transition: "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //       backgroundColor: "transparent !important",
    //     },
    //     "& .Mui-expanded": {
    //       margin: "1px 0 !important",
    //       minHeight: "0px !important",

    //     },
    //   },
    //   "& rounded": {
    //     margin: "0px 0",
    //   },
    // },
    // MuiAccordionSummary: {
    //   root: {
    //     "& .Mui-expanded": {
    //       minHeight: "40px",
    //       padding: "5px !important",

    //     },
    //   },
    //   content: {
    //     "& .Mui-expanded": {
    //       color: "#000000 !important"
    //     },
    //   }
    // },




    // MuiListItem: {
    //   gutters: {
    //     paddingLeft: "none"
    //   }
    // },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: "3.1875rem",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        padding: "0px !important",
        background: "#2B2C31",
        borderRadius: "30px 30px 10px 10px",
      },
      paperWidthXs: {
        maxWidth: "368px",
        background: "linear-gradient(189.12deg, #222326 1.11%, #2B2D33 37.04%, #363637 60.99%, #252628 76.03%)",
        boxShadow: "0px 0px 20px rgb(0 0 0 / 50%)",
        borderRadius: "30px",
        borderRadius: "10px",
        position: "absolute",
        right: "0",
        top: "47px",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
      icon: {
        color: "white ",
      },
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        paddingLeft: "15px",
        borderRadius: "18px",
      },
      outlined: {
        minWidth: "130px",

        borderRadius: "4px",
      },

      // outlined:{
      //   minWidth:'130px',
      //   background:'#6437C0',
      //   borderRadius:'100px',

      // },
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        fontWeight: 500,
        color: "white",
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        background: "#101113",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbarLandscape: {
        background: "black",
      },
      toolbar: {
        background: "black",
      },
    },

    MuiInput: {
      root: {
        borderRadius: "10px",
        height: "45px",
      },
      underline: {
        "&:hover": {
          borderBottom: "none",
        },
        "&::before": {
          borderBottom: "none",
          display: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
    MuiContainer: {
      root: {
        "@media(min-width:600px)": {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
    },
    MuiInputBase: {
      root: {
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "12px",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: "500",
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em",
        backgroundColor: "transparent !important",
      },
      input: {
        height: "34px !important",
        padding: "6px 12px 7px",
        fontSize: "13px !important",

      },
      inputMultiline: {
        height: "auto !important"
      }
    },
    // MuiFormGroup: {
    //   root: {
    //     display: "flex",
    //     flexWrap: "inherit",
    //     flexDirection: "row",
    //     justifyContent: "space-around",
    //   },
    // },
    MuiFormControlLabel: {
      root: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: "1px",
        marginRight: "16px",
        verticalAlign: "middle",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "0px",
        marginBottom: "0px",

      },
      marginDense: {
        marginTop: "0px",
      },
    },
    MuiIconButton: {
      colorPrimary: { color: "#fff" },
      edgeEnd: { marginRight: "0px" },
      root: {
        flex: "0 0 auto",
        // color: "#fff",
        // color: theme.palette.text.primary,
        padding: "8px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiButton: {
      boxShadow: "0px 0px 20px rgb(0 0 0 / 20%)",
      contained: {
        borderRadius: "100px",
        // background: "linear-gradient(180deg, #424344 0%, #232427 100%);",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#6437C0 !important"
        },
        // "&:active": {
        //   background: "#6437C0 !important",
        //   boxShadow: "none !important",
        // },
      },
      textPrimary: {
        color: "#fff",
      },
      containedSizeLarge: {
        height: "50px",
        minWidth: "150px",
        fontSize: "14px",
      },
      containedPrimary: {
        background: "linear-gradient(180deg, #424344 0%, #232427 100%);",
        padding: "8px"
      },
      containedSecondary: {
        background:
          "radial-gradient(98.18% 613.64% at 78.18% 50%, rgba(51, 153, 170, 0.81) 0%, rgba(51, 153, 170, 0) 100%) , radial-gradient(60% 120.26% at 40% 60%, #6437C0 0%, #6437C0 100%) , linear-gradient(159.8deg, #7784DA 24.32%, #7784DA 53.17%)",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "transparent",
      },
      elevation1: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: "4px",
        // "@media (max-width:767px)": {
        //   borderRadius: "5px",
        // },
      },
    },

    MuiOutlinedInput: {
      adornedEnd: {
        boxShadow: "none",
      },
      inputAdornedEnd: {

      },
      inputAdornedStart: {
        paddingLeft: 14,
        padding: "4px !important"
      },
      root: {
        position: "relative",

        borderRadius: "4px",
        border: "none",

      },
      input: {
        padding: "7px 14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
        // "@media (max-width:767px)": {
        //   padding: " 6px 8px",
        //   fontSize: "10px",
        // },
      },
    },
    MuiMenu: {
      list: {
        background: '#1E1E1E'
      }
    },

    MuiList: {
      padding: {
        paddingTop: "none",
        paddingBottom: "none",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiAvatar: {
        colorDefault: {
          background: "linear-gradient(180deg, #eaeced 0%, #dfe0e4 100%)",
        },
      },
      MuiFormLabel: { root: { color: "#000" } },
      MuiFilledInput: {
        underline: {
          "&::before": {
            left: "0", 
            right: "0", 
            bottom: "0", 
            content: "\"\\00a0\"", 
            position: "absolute",
             transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms", 
             borderBottom: "none !important", pointerEvents: "none",
          },
        },
          root: { backgroundColor: " #e5e5e5", color: "#000" },
          inputAdornedStart: { height: "15px !important" },
        },
        MuiTypography: {
          colorPrimary: { color: "#000" },
        },
        MuiOutlinedInput: {
          inputMultiline: {
            padding: "1px !important",
          },
          input: {
            borderRadius: "10px",
            padding: "0px 0px !important",
            "&:-webkit-autofill": {
              "-webkit-background-clip": "text !important",
              // transitionDelay: "9999s",
              "caret-color": "transparent",
              "-webkit-box-shadow": "0 0 0 100px transparent inset",
              "-webkit-text-fill-color": "#000",
            },
            "&:-internal-autofill-selected": {
              color: "#fff",
            },
          },
        },
        MuiInputBase: {
          root: {
            backgroundColor: " #f4f2f2",
            color: "#000",
            padding: "5px 12px",
          },
          input: {
            fontSize: "13px !important",
            fontWeight: "400",
            "&::placeholder": {
              opacity: 1,
              color: "#a1a1a1",
            },
          },

        },
      },
      typography: {
        fontFamily: "'K2D', sans-serif",
      },
      palette: {
        type: "light",
        action: {
          active: colors.blueGrey[600],
        },
        background: {
          Refferal: "#f5f5f5",
          walletselect: "#ccd7e2",
          walletselect1: "#ccd7e2",
          default: "#f5f5f5",
          cardbacground:"#f5f5f5",
          dark: "#f4f6f8",
          paper: "#ECECEC",
          subpaper: "#242526",
          brown: "#f5f5f5",
          cardhead: "linear-gradient(180deg, #e8e6e6 0%, #cbcbcb 100%);",
          tablehead: "F5F5F5",
          FormBackground: "#212226",
          AccordianBackground: "#e5e5e5",
          AccordianInBack: "#e5e5e5",
          formBack: "#e6e8ea",
          CardBack: "#e6e8ea",

        },
        circularslider: { backgrounds: "#2C3A48" },

        primary: {
          main: "#000080",

        },
        secondary: {
          main: "#FF9933",
        },
        button: {
          primary:
            "radial- gradient(98.18 % 613.64 % at 78.18 % 50 %, rgba(51, 153, 170, 0.81) 0 %, rgba(51, 153, 170, 0) 100 %), radial- gradient(60 % 120.26 % at 40 % 60 %, #6437C0 0 %, #6437C0 100 %), linear- gradient(159.8deg, #7784DA 24.32 %, #7784DA 53.17 %)",
          border: "1px solid #3399AA",
          boxShadow:
            "0px 0px 20px rgba(0, 0, 0, 0.5), inset 0px 37px 26px #7784DA",
        },

        button: {
          primary:
            "radial- gradient(98.18 % 613.64 % at 78.18 % 50 %, rgba(51, 153, 170, 0.81) 0 %, rgba(51, 153, 170, 0) 100 %), radial- gradient(60 % 120.26 % at 40 % 60 %, #6437C0 0 %, #6437C0 100 %), linear- gradient(159.8deg, #7784DA 24.32 %, #7784DA 53.17 %)",
          border: "1px solid #3399AA",
          boxShadow:
            "0px 0px 20px rgba(0, 0, 0, 0.5), inset 0px 37px 26px #7784DA",
        },
        text: {
          primary: colors.blueGrey[900],
          secondary: colors.blueGrey[600],
          noticetext: colors.blueGrey[900],
        },
      },
      shadows: softShadows,
    },
  {
    name: "DARK",
    overrides: {
      MuiAvatar: {
        colorDefault: {
          background: "linear-gradient(180deg, #424344 0%, #232427 100%);",
        },
      },
      MuiFormLabel: { root: { color: "#fff" } },
      MuiFilledInput: {
        root: { backgroundColor: "#2B2C31", color: "#fff" },
        inputAdornedStart: { height: "15px !important" },
      },
      MuiTypography: {
        colorPrimary: { color: "#fff" },
      },
      MuiOutlinedInput: {
        inputMultiline: {
          padding: "1px !important",
        },
        input: {
          borderRadius: "10px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#fff",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: " transparent !important",
          color: "#fff",
        },
        input: {
          fontSize: "13px !important",
          fontWeight: "400",
          "&::placeholder": {
            opacity: 1,
            color: "#a1a1a1",
          },
        },
        multiline: {
          backgroundColor: "#1E1E1E",
          border: "none",
          borderRadius: "10px",
        },
      },
    },
    typography: {
      fontFamily: "'K2D', sans-serif",
    },
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        Refferal: "linear-gradient(180deg, rgba(167,53,211,1) 0%, rgba(57,174,208,1) 100%)",
        walletselect: "#6437C0",
        walletselect1: "linear-gradient(180deg, #424344 0%, #232427 100%)",
        default: "linear-gradient(189.12deg, #222326 1.11%, #2B2D33 37.04%, #363637 60.99%, #252628 76.03%)",
        cardbacground:"#212226",
        dark: "#1c2025",
        paper:
          "linear-gradient(189.12deg, #222326 1.11%, #2B2D33 37.04%, #363637 60.99%, #252628 76.03%);",
        backgroundnotification: "#212226",
        formBack: "#212226",
        CardBack: "linear-gradient(180deg, #424344 0%, #232427 100%)",
        subpaper: "linear-gradient(180deg, #424344 0%, #232427 100%)",
        header: "linear-gradient(180deg, #424344 0%, #232427 100%)",
        brown: "#2B2C31",
        cardhead: "linear-gradient(180deg, #424344 0%, #232427 100%);",
        tablehead: "linear-gradient(180deg, #424344 0%, #232427 100%)",
        FormBackground: "#212226",
        AccordianBackground: "#212226",
        AccordianInBack: "#292A2F",
      },
      primary: {
        main: "#212226",
      },
      secondary: {
        main: "#FA9131",
      },
      button: {
        primary: "linear-gradient(180deg, #424344 0%, #232427 100%)",
      },
      circularslider: {
        backgrounds: "#212226",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
        noticetext: "#fff",
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
