import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import DashboardLayoutDash from "src/layouts/DashboardLayout/indexDash";
// import HomeLayout from 'src/layouts/HomeLayout';
// import LoginLayout from "src/layouts/LoginLayout";
export const routes = [


  {
    exact: true,
    path: "/",
    // guard:true,
    layout: DashboardLayoutDash,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    path: "/cos",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Cosafapopup")),
  },

  // {
  //   exact: true,
  //   path: "/ActiveBits",
  //   component: lazy(() => import("src/view/pages/mygBets/ActiveSettle")),
  //   layout: DashboardLayout,

  //  },
  {
    exact: true,
    path: "/AllBitsAccordian",
    component: lazy(() => import("src/component/AllBitsAccordian")),
    // layout: DashboardLayout,

  },
  // {
  //   exact: true,
  //   path: "/SettleBits",
  //   component: lazy(() => import("@/views/pages/MyBets/SattleCom")),
  //   layout: DashboardLayout,

  // },
  {
    exact: true,
    path: "/acc",
    component: lazy(() => import("src/component/AccordionComponent")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/my-account",
    component: lazy(() => import("src/views/pages/MyAccount/MyAccount")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/edit-profile",
    component: lazy(() => import("src/views/pages//MyAccount/EditProfle")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/wallet",
    component: lazy(() => import("src/views/pages/Wallet/Index")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/faq",
    component: lazy(() => import("src/views/pages/Frequently/Faq")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/favourites",
    component: lazy(() => import("src/views/pages/Favourite/Favourite")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/support",
    component: lazy(() => import("src/views/pages/Support/SuportForm")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/notifications",
    component: lazy(() => import("src/views/pages/Notification/Notification")),
    layout: DashboardLayout,

  },
  {
    exact: true,
    path: "/transaction",
    component: lazy(() => import("src/views/pages/Transaction/TransctionTable")),
    layout: DashboardLayout,

  },

  {
    exact: true,
    path: "/bets",
    component: lazy(() => import("src/views/pages/CosafaCupGame/BetSelectBuilder")),
    layout: DashboardLayout,

  },

  {
    exact: true,
    path: "/my-bets",
    component: lazy(() => import("src/views/pages/MyBets/MyBets")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/referrals",
    component: lazy(() => import("src/views/pages/Refferal/Referral")),
    layout: DashboardLayout,
  },


  {
    exact: true,
    path: "/leaderBoard",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LeaderBoard/LeaderBoard")),
  },
  {
    exact: true,
    path: "/rules",
    component: lazy(() => import("src/views/pages/Rules/Bettings")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
