import React from 'react';
import { makeStyles, TextField, Box,IconButton, InputAdornment, } from '@material-ui/core';
import { BsSearch } from "react-icons/bs";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // padding: "0px 15px"
    }
}));

function GloballySearchArea() {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.root}>
                <TextField
                    id="outlined-basic"
                    type="search"
                    variant="outlined"
                    fullWidth
                    placeholder="Search"
                    style={{ borderRadius: "100px", border: "1px solid #35373E", maxWidth: "256px" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {" "}
                                <IconButton
                                    style={{ fontSize: "20px", padding: "0px" }}
                                    className="iconbtn"
                                >
                                    <BsSearch style={{ color: "#8d8d8f", fontSize: "16px" }} />
                                </IconButton>{" "}
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </>
    )
}

export default GloballySearchArea;