import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Container, Avatar
} from "@material-ui/core";
import { MdOutlineContentCopy } from "react-icons/md";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import BetBuilderModal from "src/component/BetBuilderModal";
import MenuIcon from "@material-ui/icons/Menu";
import { BsBellFill } from "react-icons/bs";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import GloballySearchArea from "src/component/GloballySearchArea";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import InputBase from '@material-ui/core/InputBase';
import UserProfileComponent from "src/component/UserProfileComponent";
import { Link } from "react-router-dom";
import Logo from "src/component/Logo";
import BetBuilder from "src/views/pages/CosafaCupGame/BetBuilder";
import SettingsContext from "src/context/SettingsContext";
import { ModalContext } from "src/component/context";

const useStyles = makeStyles((theme) => ({
  Topbar: { boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)", },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    margin: "7px 0",
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#1a1a1a",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "6px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },
  menuul: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: 'block',
    },
    "& li": {
      listStyle: "none", marginLeft: "10px",
      [theme.breakpoints.down("lg")]: {
        // marginTop: '15px',

      }
    },
    "& button": {
      height: "41px",
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    }

  },
  menuSocial: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "& li": { listStyle: "none", marginLeft: "10px" },
    "& .buttontopBox": {
      background: "linear-gradient(180deg, #00FFAB 0%, #00FFAB 100%)",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
      color: "#000000",
      padding: "8px",
      "& svg": {
        fontSize: "15px"
      }
    },
    // "& button": {
    //   marginRight: '15px',
    //   width: "100%",
    //   height: "41px",
    // },
    "&  .selectbox3": {
      background: "linear-gradient(180deg, #424344 0%, #232427 100%)",
      boxShadow: "0 0 1px 0 rgb(0 0 0 / 70%), 0 2px 2px -2px rgb(0 0 0 / 50%)", borderRadius: "100px",
      backgroundColor: "#e0e0e0",
      height: "41px",
      "& .selectBox": {
        display: "flex",
        alignItems: "center",
        "& h5": {
          fontSize: "14px",
          marginLeft: "7px",
          fontWeight: "700",
        },
        "& img": {
          width: "22px",
        },
      },
    },

  },
  modalButtonBox: {
    "& button": {
      padding: "12px 0 !important",
      "&:hover": {
        backgroundColor: "#6437C0 !important"
      },
      // "&:active": {
      //   background: "#6437C0 !important",
      //   boxShadow: "none !important",
      // },
    }
  }



}));

export default function Header({ buttonClick }) {

  const {
    toolbar, Topbar,
    menuul,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader, menuSocial, modalButtonBox
  } = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const history = useHistory();
  const [showModal, updateShowModal] = React.useState(false);
  const toggleModal = () => updateShowModal(state => !state);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);


  const [select1, setSelect1] = useState("BTC");
  const handleChange1 = (event) => {
    setSelect1(event.target.value);
  };
  const [openUserProfile, setOpenUserProfile] = React.useState(false);

  const handleClickOpen = () => {
    setOpenUserProfile(true);
  };

  const handleClose = () => {
    setOpenUserProfile(false);
  };

  const displayDesktop = () => {

    return (
      <Container maxWidth="fixed" className="p-0">
        <Toolbar className={toolbar}>
          <Grid container spacing={2} alignItems='center' justifyContent="center">
            <Grid item md={2}>
              {productLogo}
            </Grid>
            <Grid item md={10}>
              <Grid container spacing={2} alignItems='center' justifyContent="center">
                <Grid item xs={7}>
                  <Grid container spacing={1} alignItems='center' justifyContent="center">
                    <Grid item xs={4}>
                      <GloballySearchArea />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/LeaderBoard")} >
                            Leaderboard
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button variant="contained" color="primary" fullWidth >
                            Bet Builder
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button variant="contained" color="primary" fullWidth >
                            House Cut : <span>3%</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Box className={menuSocial}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item md={3}>
                        <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/wallet")} className="buttontopBox">
                          <BsFillPlusCircleFill />&nbsp;&nbsp;Deposit
                        </Button>
                      </Grid>
                      <Grid item md={3}>
                        <Select
                          className="selectbox3"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="weather"
                          value={select1}
                          onChange={handleChange1}
                        >
                          <MenuItem value={"BTC"}>
                            <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                              <img src="images/btc.png" alt="image" style={{ width: "22px" }} />
                              <Typography variant="h5" style={{
                                fontSize: "14px",
                                marginLeft: "7px",
                              }}>BTC</Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"BNB"}>
                            <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                              <img src="images/bnb.png" alt="image" style={{ width: "22px" }} />
                              <Typography variant="h5" style={{
                                fontSize: "14px",
                                marginLeft: "7px",
                              }}>BTC</Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"ETH"}>
                            <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                              <img src="images/eth.png" alt="image" style={{ width: "22px" }} />
                              <Typography variant="h5" style={{
                                fontSize: "14px",
                                marginLeft: "7px",
                              }}>BTC</Typography>
                            </Box>
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3}>
                        <Button variant="contained" color="primary" fullWidth >
                          < FaDollarSign /> 1254.00
                        </Button>
                      </Grid>
                      <Grid item md={3}>
                        <Box style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around"
                        }} >
                          <IconButton variant="contained" color="primary" onClick={() => history.push("/notifications")}  >
                            <BsBellFill />
                          </IconButton>
                          <Avatar alt="Remy Sharp" src="/images/user.png" onClick={handleClickOpen}
                            style={{ cursor: "pointer" }} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {
          openUserProfile && (
            <Dialog
              fullWidth
              maxWidth="xs"
              open={openUserProfile}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <UserProfileComponent />
            </Dialog>
          )
        }
        <ModalContext.Provider value={{ showModal, toggleModal }}>
          <div>
            <BetBuilderModal canShow={showModal} updateModalState={toggleModal}>
              <BetBuilder />
            </BetBuilderModal>
          </div>
        </ModalContext.Provider>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logotype02.png" alt="" />
            <Box style={{ width: "100%", padding: "0px 10px" }}>
              <GloballySearchArea />
            </Box>
            <UserProfileComponent />
            <Box className={menuSocial} px={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/LeaderBoard")} >
                    Leaderboard
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth onClick={toggleModal}>
                    Bet Builder
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth >
                    House Cut : <span>3%</span>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/wallet")} className="buttontopBox">
                    <BsFillPlusCircleFill />&nbsp;&nbsp;Deposit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    className="selectbox3"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="weather"
                    value={select1}
                    onChange={handleChange1}
                  >
                    <MenuItem value={"BTC"}>
                      <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                        <img src="images/btc.png" alt="image" style={{ width: "22px" }} />
                        <Typography variant="h5" style={{
                          fontSize: "14px",
                          marginLeft: "7px",
                        }}>BTC</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={"BNB"}>
                      <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                        <img src="images/bnb.png" alt="image" style={{ width: "22px" }} />
                        <Typography variant="h5" style={{
                          fontSize: "14px",
                          marginLeft: "7px",
                        }}>BTC</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={"ETH"}>
                      <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                        <img src="images/eth.png" alt="image" style={{ width: "22px" }} />
                        <Typography variant="h5" style={{
                          fontSize: "14px",
                          marginLeft: "7px",
                        }}>BTC</Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth >
                    < FaDollarSign /> 1254.00
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {/* {menuLinks} */}
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" style={{ width: "170px" }} />
      </Link>
    </Box>
  );

  return (

    <>
      <AppBar
        className={Topbar}
        elevation={0}
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <Box className="themeButton">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            changeTheme("LIGHT");
          }}
        >
          <FiSun />
        </IconButton>
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            changeTheme("DARK");
          }}
        >
          <FaRegMoon />
        </IconButton>
      </Box>
    </>
  );
}
