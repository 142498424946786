import React, { useState } from "react";
import {
  makeStyles,
  withStyles,
  Typography,
  Box,
  Button,
  Grid,
  Select,
  MenuItem,
  Divider,
  FormControl,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import { BsSearch } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .headingBox": {
      width: "100%",
      // background: "linear-gradient(180deg, #424344 0%, #232427 100%)",
      background: theme.palette.background.cardhead,
      borderRadius: "30px 30px 0px 0px",
      padding: "15px",
      position: "relative",
      "& .buttonBox": {
        position: "absolute",
        left: "50px",
      },
      "& .logoBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& figure": {
          margin: "0px",
          "& img": {
            width: "100px",
          },
        },
        "& h6": {
          marginLeft: "8px",
        },
      },
    },
    "& .mainBox": {
      padding: "15px",
    },
  },
  formControl: {
    backgroundColor: "#6437C0 !important",
    borderRadius: "4px !important",
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  selectbox: {
    backgroundColor: "#6437C0 !important",
    borderRadius: "100px !important",
    boxShadow: "none",
    marginRight: "10px",
  },
  selectbox3: {
    borderRadius: "4px",
    background: "#2B2C31",
    boxShadow:
      "inset 0px -1px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.51)",
  },
  formControl2: {
    width: "100%",
    borderRadius: "100px !important",
  },
  AccordionBox: {
    display: "flex",
    background: theme.palette.background.AccordianBackground,
    width: "100%",
    justifyContent: "space-between",
    borderRadius: "4px",
    padding: "20px",
    "& p": {
      color: "#7784DA",
    },
    "& .butnsec": {
      display: "flex",
      flexDirection: "row",
      "& button": {
        width: "83px",
        // marginRight:"85px",
        height: "30px",
        background:
          "radial-gradient(60.37% 50% at 50% 50%, rgba(255, 16, 16, 0.5) 0%, rgba(255, 0, 0, 0) 100%), linear-gradient(159.8deg, #FDB26D 24.32%, #FA6131 89.49%)",
        boxShadow: "0px 0px 11px #CF2B2B",
        borderRadius: "10px",
      },
    },
  },
  dialogboxModal: {
    "& .borderBox": {
      background: "linear-gradient(180deg, #424344 0%, #232427 100%)",
      padding: "30px",
    },
  },
  dialogbox: {
    position: "relative",
    maxHeight: "95%",
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
    },
  },
  selectbox2: {
    borderRadius: "4px",
    background: theme.palette.background.walletselect1,
  },
  selectbox3: {
    borderRadius: "4px",
    background: "#2B2C31",
    boxShadow:
      "inset 0px -1px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.51)",
  },
  formControl2: {
    width: "100%",
    borderRadius: "100px !important",
    "& .selectmodalBox": {
      boxShadow:
        "inset 0px -1px 4px rgb(0 0 0 / 25%), inset 0px 4px 4px rgb(0 0 0 / 51%)",
      borderRadius: "4px",
      padding: "10px",
      cursor: "pointer",
    },
  },
  availablebox:{
    position:"relative"
  },
  datepikerimage:{
     position:'absolute',
     left:'50%',
     top:'25px',
     transform:"translate(-50% -50%)"
  }
}));

const Accordion = withStyles((theme) => ({
  root: {
    "&:not(:last-child)": {
      background: "#000",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      border: " 1px solid #3d3d3d",
      background:
        "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      backdropFilter: "blur(42px)",
    },
  },
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    // marginTop: "22px",
    borderRadius: "0",
    // background: "linear-gradient(180deg, #424344 0%, #232427 100%)",
    background: theme.palette.background.CardBack,
    "&$expanded": {
      minHeight: 50,
      borderBottom: "0",
      color: "#FFF",
      // backgroundColor: "transparent",
      border: " none !important",
    },
    "@media(max-width:605px)": {
      fontSize: "10px",
      "&$expanded": {
        borderBottom: "0",
        color: "#FFF",
        backgroundColor: "transparent",
        border: " none !important",
      },
    },
  },
  content: {
    background: "000 !important",
    color: "#fff",
    borderRadius: "5px",
    // background-color: transparent;
    // border: none !important;
    "&$expanded": {
      margin: "0px 0",
      // backgroundColor: "transparent",
      border: " none !important",
    },
  },
  expanded: {
    margin: "0",
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // background: "#393A40",
    background: theme.palette.background.AccordianInBack,
    padding: "1px 16px 8px",
    "& svg": {
      color: "red",
    },
  },
}))(MuiAccordionDetails);

const detailscard = [
  {
    image: "images/image 3.png",
    discription: "ETH",
  },
  {
    image: "images/image 3.png",
    discription: "ETH",
  },
  {
    image: "images/image 3.png",
    discription: "ETH",
  },
  {
    image: "images/image 3.png",
    discription: "ETH",
  },
  {
    image: "images/image 3.png",
    discription: "ETH",
  },
];

function BetBuilder() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  // const [language, setLanguage] = useState("10");
  // const [select1, setSelect1] = useState("BTC");
  // const handleChange1 = (event) => {
  //     setSelect1(event.target.value);
  // };
  const [language, setLanguage] = useState("Lakers");
  const [language2, setLanguage2] = useState("Phoenix Suns");
  const [bet, setBet] = useState("Team total");
  const [status, setStatus] = useState("Status");
  const [event, setEvent] = useState("Event");
  const [awards, setAwards] = useState("Awards");
  const [games, setGames] = useState("November 20th, 2022");
  const [player, setPlayer] = useState("Select Player 1");
  // const [language, setLanguage] = useState("10");
  const [open, setOpen] = React.useState(false);
  const [select1, setSelect1] = useState("BTC");
  const handleChange1 = (event) => {
    setSelect1(event.target.value);
  };
  const [select, setSelect] = useState("Deposite");
  const [widthdraw, setWidthdraw] = useState("Withdraw");
  const handleChange2 = (event) => {
    setWidthdraw(event.target.value);
  };
  const handleChange = (event) => {
    setSelect(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className="headingBox">
          <Box className="buttonBox">
            <Typography variant="h6">BET BUILDER</Typography>
          </Box>
          <Box className="logoBox">
            <figure>
              <img src="images/logo.png" alt="image" />
            </figure>
            <Typography variant="h6">BET BUILDER - Create a Bet</Typography>
          </Box>
        </Box>
        {/* <Box> <BetSelectBuilder /></Box> */}
        <Box>
          <Box className="Banner">
            <Box className="mainBox" mt={2}>
              <Typography variant="h5" style={{ color: "#787878" }}>
                Create your own Bet
              </Typography>
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Typography variant="body2">Select League :</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Box>
                      <FormControl className={classes.formControl2}>
                        <Box
                          className="selectmodalBox"
                          onClick={handleClickOpen}
                        >
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs="6">
                              <Box display="flex" alignItems="center">
                                <img src="./images/image 3.png" alt="image" />
                                <Box ml={1}>
                                  <Typography
                                    variant="body1"
                                    style={{ cursor: "pointer" }}
                                  >
                                    ETH
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs="6" align="right">
                              <MdArrowDropDown
                                style={{
                                  fontSize: "20px",
                                  marginRight: "-7px",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Typography variant="body2">Select Team 1 :</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Box>
                      <Select
                        fullWidth
                        className={classes.selectbox3}
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        // input={<BootstrapInput />}
                      >
                        <MenuItem value="Lakers">
                          <Box display="flex" alignItems="center">
                            <img src="/images/lakers.png" alt="image" />
                            <Box ml={1}>La Lakers</Box>
                          </Box>
                        </MenuItem>
                        <MenuItem value="one">
                          <Box display="flex" alignItems="center">
                            <img src="/images/lakers.png" alt="image" />
                            <Box ml={1}>one</Box>
                          </Box>
                        </MenuItem>
                        <MenuItem value="two">
                          <Box display="flex" alignItems="center">
                            <img src="/images/lakers.png" alt="image" />
                            <Box ml={1}>two</Box>
                          </Box>
                        </MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Typography variant="body2">Select Team 2 :</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Box>
                      <Select
                        fullWidth
                        value={language2}
                        className={classes.selectbox3}
                        onChange={(e) => setLanguage2(e.target.value)}
                        // input={<BootstrapInput />}
                      >
                        <MenuItem value="Phoenix Suns">
                    <Box display="flex"alignItems="center">
                      <img src="/images/suns.png" alt="image"/>
                      <Box ml={1}>
                      Phoenix Suns
                      </Box>
                    </Box>
                  </MenuItem>
                        <MenuItem value="20">Twenty</MenuItem>
                        <MenuItem value="30">Thirty</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Box className={classes.availablebox}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Typography variant="body2">
                        See Available Games :
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Box>
                      <Select
                        fullWidth
                        value={games}
                        className={classes.selectbox3}
                        onChange={(e) => setGames(e.target.value)}
                        // input={<BootstrapInput />}
                      >
                       <MenuItem value="November 20th, 2022">
                    <Box display="flex" alignItems="center">
                    November 20th, 2022
                    <Box ml={2} display="flex" alignItems="center">
                      <img src="/images/suns.png" alt="image"/>
                     <Box ml={1}>
                     vs
                     </Box>
                     <Box ml={1}>
                     <img src="/images/lakers.png" alt="image"/>
                     </Box>
                    </Box>
                    </Box>
                  </MenuItem>
                        <MenuItem value="20">Twenty</MenuItem>
                        <MenuItem value="30">Thirty</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Box className={classes.datepikerimage}>
                    <img src="/images/datepiker.png" />
                </Box>
                </Box>
              </Box>
              <Box my={2}>
                <Divider />
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Typography variant="body2">Select Bet Type :</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Box>
                      <Select
                        className={classes.selectbox3}
                        fullWidth
                        value={bet}
                  onChange={(e) => setBet(e.target.value)}
                        // input={<BootstrapInput />}
                      >
                        <MenuItem value="Team total">Team total</MenuItem>
                        <MenuItem value="20">Twenty</MenuItem>
                        <MenuItem value="30">Thirty</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Typography variant="body2">Select Category :</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Select
                        fullWidth
                        value={status}
                  onChange={(e) => setStatus(e.target.value)}
                        // input={<BootstrapInput />}
                        className={classes.selectbox3}
                      >
                          <MenuItem value="Status">Status</MenuItem>
                        <MenuItem value="20">Twenty</MenuItem>
                        <MenuItem value="30">Thirty</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Select
                        fullWidth
                        value={event}
                  className={classes.selectbox3}
                  onChange={(e) => setEvent(e.target.value)}
                        // input={<BootstrapInput />}
                      >
                        <MenuItem value="Event">Event</MenuItem>
                        <MenuItem value="20">Twenty</MenuItem>
                        <MenuItem value="30">Thirty</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Box>
                      <Select
                        fullWidth
                        value={awards}
                  className={classes.selectbox3}
                  onChange={(e) => setAwards(e.target.value)}
                        // input={<BootstrapInput />}
                      >
                        <MenuItem value="Awards">Awards</MenuItem>
                        <MenuItem value="20">Twenty</MenuItem>
                        <MenuItem value="30">Thirty</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" mt={1}>
                  <Grid
                    item
                    xs={6}
                    md={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box>
                      <Typography variant="body2">Select Player :</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <label style={{ color: "#787878" }}>Optional</label>
                    <Box mt={1}>
                      <Select
                        fullWidth
                        value={player}
                        className={classes.selectbox3}
                        onChange={(e) => setPlayer(e.target.value)}
                        // input={<BootstrapInput />}
                      >
                       <MenuItem value="Select Player 1">Select Player 1</MenuItem>
                        <MenuItem value="20">Twenty</MenuItem>
                        <MenuItem value="30">Thirty</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2} align="center">
                    <Box className="flexjustify" mt={3}>
                      <img
                        src="images/add_playerbutton.png"
                        alt="image"
                        style={{ cursor: "pointer" }}
                      />
                      <Typography variant="body2" style={{ marginLeft: "7px" }}>
                        Add Player
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}></Grid>
                  <Grid item xs={6} md={4}>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        style={{
                          background: "#6437c0",
                          width: "calc(100% - 162px)",
                        }}
                      >
                      View All Available Bets
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth
              maxWidth="sm"
              className={classes.dialogboxModal}
            >
              <Box className="borderBox">
                <IconButton
                  className={classes.icons}
                  onClick={handleClose}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "0",
                    zIndex: "999",
                  }}
                >
                  <img
                    src="images/close.png"
                    alt="image"
                    onClick={handleClose}
                  />
                </IconButton>
              </Box>

              <Box className={classes.dialogbox} mt={2}>
                <DialogContent>
                  <DialogContentText>
                    <Box>
                      <TextField
                        id="outlined-basic"
                        type="search"
                        variant="outlined"
                        placeholder="Search"
                        style={{
                          border: "1px solid #35373E",
                          borderRadius: "100px",
                          width: "100%",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {" "}
                              <IconButton
                                style={{ fontSize: "20px", padding: "0px" }}
                                className="iconbtn"
                              >
                                <BsSearch
                                  style={{ color: "#35373E", fontSize: "16px" }}
                                />
                              </IconButton>{" "}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box mt={2}>
                      <Grid container spacing={1}>
                        {detailscard.map((data, i) => {
                          return (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    style={{
                                      borderBottom: "1px solid #424242",
                                      padding: "10px 0",
                                    }}
                                  >
                                    <img src={data.image} alt="image" />
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontSize: "14px",
                                        marginLeft: "7px",
                                      }}
                                    >
                                      {data.discription}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Dialog>
          </Box>
        </Box>

        <Box className="betBox">
          <Typography variant="body1">Bet 1</Typography>
          <Box mt={1}>
            <Accordion square defaultExpanded={true}>
              <AccordionSummary
                id="panel1a-header"
                expandIcon={
                  expanded === true ? (
                    <AiOutlineMinus
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",

                        color: "#fff",
                      }}
                    />
                  ) : (
                    <AiOutlinePlus
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",

                        color: "#fff",
                      }}
                    />
                  )
                }
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Selected bet
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box mt={1}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Box className={classes.AccordionBox}>
                        <Box style={{ maxWidth: "500px" }}>
                          <Typography variant="body1">
                            In the next 8 mins, will Westbrook lob the ball to
                            James for a dunk
                          </Typography>
                        </Box>

                        <Box className="butnsec">
                          <Button
                            variant="contained"
                            style={{ marginRight: "60px" }}
                          >
                            +300
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              color: "#FA9131",
                              background: "white",
                              boxShadow: "none",
                            }}
                          >
                            +150
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className={classes.AccordionBox}>
                        <Box style={{ maxWidth: "500px" }}>
                          <Typography variant="body1">
                            In the next 8 mins, will Westbrook lob the ball to
                            James for a dunk
                          </Typography>
                        </Box>

                        <Box className="butnsec">
                          <Button
                            variant="contained"
                            style={{ marginRight: "60px" }}
                          >
                            +300
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              color: "#FA9131",
                              background: "white",
                              boxShadow: "none",
                            }}
                          >
                            +150
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BetBuilder;
