import { Link, NavLink } from "react-router-dom";
import React from "react";
import { makeStyles, Button, MenuItem, Avatar, Grid, } from "@material-ui/core";

import clsx from 'clsx';
import {IoBasketballOutline} from "react-icons/io5";
import {FaUserAlt} from 'react-icons/fa';
import {FaWallet} from 'react-icons/fa';
import {MdStarRate} from 'react-icons/md';
import {IoMdNotificationsOutline} from 'react-icons/io';
import {HiUsers} from 'react-icons/hi';
import {FaQuestionCircle} from 'react-icons/fa';
import {BiSupport} from 'react-icons/bi';
import {GiTwoCoins} from 'react-icons/gi';
import {BiTransfer} from 'react-icons/bi';
import {MdLeaderboard} from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';



const useStyles = makeStyles((theme) => ({

  MenuItem: {
    padding: "10px",
    color: "#787878",
    padding:"10px 10px",
    fontSize:"16px",
   
    "& svg":{
      fontSize:"15px",
      color: "#787878",
      
    },
    "& .menuText":{
     paddingLeft:"10px",
      
    },
    "& .MuiAvatar-root":{
      width: "30px",
      height: "30px",
      
    }
  },

}));
const headersData = [
  {
    label: 'My Account',
    href: '/my-account',
    icon: <FaUserAlt/>,
  },
  {
    label: 'Wallet',
    href: '/wallet',
    icon: <FaWallet/>,
  },
  {
    label: 'Favourites',
    href: '/favourites',
    icon: <MdStarRate/>,
  },
  {
    label: 'Notification',
    href: '/notifications',
    icon: <IoMdNotificationsOutline/>,
  },
  {
    label: 'Transactions',
    href: '/transaction',
    icon: <BiTransfer/>,
  },
  {
    label: 'My Bets',
    href: '/my-bets',
    icon: <GiTwoCoins/>,
  },
  {
    label: 'Referral',
    href: '/referrals',
    icon: <HiUsers/>,
  },
  {
    label: 'Leaderboard',
    href: '/leaderBoard',
    icon: <MdLeaderboard/>,
  },
  {
    label: 'Rules',
    href: '/rules',
    icon: <IoBasketballOutline/>,
  },
  {
    label: 'FAQ',
    href: '/faq',
    icon: <FaQuestionCircle/>,
  },
  {
    label: 'Support',
    href: '/support',
    icon: <BiSupport/>,
  },
]
export default function (props) {
  const classes = useStyles();

  return headersData.map(({ label, href, icon }) => {
    return (
      <>
        <NavLink
       activeClassName="active" 
       {...{
         key: label,
         color: "inherit",
         to: href,
         
       }}
     >
          <MenuItem className={classes.MenuItem}><Avatar>{icon}</Avatar><span className="menuText">{label}</span></MenuItem>
        </NavLink>
      </>
    )
  })
}
