import { Box } from "@material-ui/core";
import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Typography,
  Grid,
  Slide,
} from "@material-ui/core";
import SettleCard from "src/component/SettleCard";
import BetBox from "src/component/BetBox";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import MyBetsActiveCard from "src/component/MyBetsActiveCard";
import ParlaySuggestion from "src/component/ParlaySuggestion";
import Dialog from "@material-ui/core/Dialog";
import { BsArrowLeft } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  acitiveBox: {

    "& button": {
      minWidth: "auto",
    },
    "& button": {
      border: "1px solid #2a2b2e",
      minWidth: "auto",
      borderRadius: "5px",
      padding: "6px 27px",
      "&.active": {
        border: "1px solid #6437C0",
        background:
          "#6437C0",
      },
    },
  },
  ButtonBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& button": { minWidth: "auto", },
    "& button": {
      minWidth: "auto",
      border: "1px solid #2a2b2e",
      "&.active": {
        border: "1px solid #3399AA",
        background:
          "radial-gradient(98.18% 613.64% at 78.18% 50%, rgba(51, 153, 170, 0.81) 0%, rgba(51, 153, 170, 0) 100%) , radial-gradient(60% 120.26% at 40% 60%, #6437C0 0%, #6437C0 100%) , linear-gradient(159.8deg, #7784DA 24.32%, #7784DA 53.17%)",
      },
    },
  },
  greenButton: {
    background: "#00FFAB",
    boxShadow: " 0px 0px 11px rgba(0, 255, 171, 0.5)",
    color: "#000",
    fontSize: "17px",
    fontWeight: 700,
    width: "calc(100% - 71px)",
  },
  fullscreenModal: {
    "& .MuiDialog-paperFullScreen": {
      top: "26px",
      height: "calc(100% - 80px)",
      background: theme.palette.background.paper,
    },
  },
  appBar: {
    position: "relative",
    borderRadius: "43px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function (props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [tabviewsideBet, setTabViewsideBet] = useState("SLIP");
  const [tabview, setTabView] = useState("ACTIVE");


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.UserBox}>
      <Box className={classes.ButtonBox}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" size="large" fullWidth className={tabviewsideBet === "SLIP" ? "active" : ""}
              onClick={() => setTabViewsideBet("SLIP")}>BET SLIP</Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" size="large" fullWidth className={tabviewsideBet === "BETS" ? "active" : ""}
              onClick={() => setTabViewsideBet("BETS")} >MY BETS</Button>
          </Grid>
        </Grid>
      </Box>
      {tabviewsideBet === "SLIP" ? (
        <>
          <Box mt={2}>
            <Typography variant="body2" color="primary">Your Bets</Typography>
            <BetBox />
            <BetBox />
          </Box>
          <Box mt={2}>
            <Box className="justifyBetween" mb={1}>
              <Typography variant="body2" color="primary" className="textBlue">Total Odds</Typography>
              <Typography variant="body2" color="primary">2</Typography>
            </Box>
            <Box className="justifyBetween" mb={1}>
              <Typography variant="body2" color="primary" className="textBlue">Total Odds</Typography>
              <Typography variant="body2" color="primary">2</Typography>
            </Box>
          </Box>
          <Box mt={2} align="center">
            <Button variant="contained" size="large" fullWidth className={classes.greenButton}>BET NOW</Button>
          </Box>
          <Box mt={2}>
            <ParlaySuggestion />
          </Box></>
      ) : (
        ""
      )}

      {tabviewsideBet === "SETTLE" ? (
        <>

          <Box className={classes.acitiveBox} mt={2} align="center">
            <Box className="flexCenter">
              <Button variant="contained" color="primary" className={tabviewsideBet === "BETS" ? "active" : ""}
                onClick={() => setTabViewsideBet("BETS")} >Active</Button>
              <Button variant="contained" color="primary" className={tabviewsideBet === "SETTLE" ? "active" : ""}
                onClick={() => setTabViewsideBet("SETTLE")} style={{ marginLeft: "15px", }}>Settle</Button>
            </Box>
          </Box>

          <Box mt={2} mb={2}>
            <SettleCard />
          </Box>
          <Box mt={2} mb={2}>
            <SettleCard />
          </Box>
          <Box mt={2} mb={2}>
            <SettleCard />
          </Box>

          <Box mt={2} >
            <Button variant="contained" size="small" fullWidth className={classes.greenButton} component={Link} to="/my-bets">View More</Button>
          </Box>
        </>
      ) : (
        ""
      )}

      {tabviewsideBet === "BETS" ? (
        <>
          <Box className={classes.acitiveBox} mt={2} align="center">
            <Box className="flexCenter">
              <Button variant="contained" color="primary" className={tabviewsideBet === "BETS" ? "active" : ""}
                onClick={() => setTabViewsideBet("BETS")} >Active</Button>
              <Button variant="contained" color="primary" className={tabviewsideBet === "SETTLE" ? "active" : ""}
                onClick={() => setTabViewsideBet("SETTLE")} style={{ marginLeft: "15px", }}>Settle</Button>

            </Box>

          </Box>


          <Box mt={2} mb={2}>
            <MyBetsActiveCard />
          </Box>
          <Box mt={2} mb={2}>
            <MyBetsActiveCard />
          </Box>
          <Box mt={2} mb={2}>
            <MyBetsActiveCard />
          </Box>
          {/* <Box mb={2}>
            <ActiveBetCard />
          </Box>
          <Box mb={2}>
            <ActiveBetCard />
          </Box> */}
          <Box mt={2}>
            <Button variant="contained" size="small" fullWidth className={classes.greenButton} onClick={() => history.push("/my-bets")}>View More</Button>
          </Box>
        </>
      ) : (
        ""
      )}


    </Box>
  );
}
