import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles, Typography, TextField, InputAdornment, Grid, } from "@material-ui/core";

import clsx from 'clsx';
import { IoBasketballOutline } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  betBox: {
    border: "0.5px solid #7784DA",
    borderRadius: "10px",
    overflow: "hidden",
    marginTop: "15px",
    background: theme.palette.background.cardbacground,
  },
  header: {

    background: "#6437C0",
    padding: "6px",
    "& svg": { marginRight: "7px", fontSize: "20px", }
  },
  betdetails: {
    padding: "10px",
  },
  textBoxBet: {
    padding: "10px",
    background: theme.palette.background.brown,
    width: "120px", marginRight: "10px",
  },
  textField: {
    background: "#2B2C31",
    border: "0.5px solid #39AED0",
    borderRadius: "5px",
    "& label": {
      color: "#39AED0",
    },
    "&  placeholder": {
      color: "#39AED0 !important",
    },
  },
}));

export default function (props) {
  const classes = useStyles();

  return (
    <Box className={classes.betBox}>


      <Box className={`${classes.header} flexjustify`}>
        < IoBasketballOutline />
        <Typography variant="body1" color="primary">27071. FIBA Word Championship</Typography>
      </Box>
      <Box mt={1} className={classes.betdetails}>
        <Typography variant="body2" color="primary">Cape Verde - Uganda</Typography>
        <Box mt={1} className="justifyBetween">
          <Box className={classes.textBoxBet} textAlign="center"><Typography variant="h5" color="primary">1.83</Typography></Box>
          <Typography variant="body2" color="primary">Handicap Handicap Cape
            Verde(-7.5) </Typography>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Wager"
                placeholder="100"
                id="inputID"
                fullWidth
                className={clsx(classes.textField)}
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={{
                    color: " #FA9131",
                    fontSize: "18px"
                  }}><Typography variant="h6">$</Typography></InputAdornment>,
                }}
                variant="filled"
              /></Grid>
            <Grid item xs={6}><TextField
              label="To Win"
              placeholder="183.00"
              id="inputID"
              fullWidth
              className={clsx(classes.textField)}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{
                  color: " #FA9131",
                  fontSize: "18px"
                }}><Typography variant="h6">$</Typography></InputAdornment>,
              }}
              variant="filled"
            /></Grid>
          </Grid>

        </Box>
      </Box>

    </Box>
  );
}
