import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles, Button, Typography, TextField, InputAdornment, Grid, Switch } from "@material-ui/core";
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  betBox: {
    padding: "10px",
    border: "1px dashed #00FFAB",
    borderRadius: "10px",
    overflow: "hidden",
    marginTop: "15px",
    background: "radial-gradient(190.42% 85.16% at 50% 50%, #3FAAD1 0%, #6437C0 100%)",
  },
  header: {

    background: "#6437C0",
    padding: "6px",
    "& svg": { marginRight: "7px", fontSize: "20px", }
  },
  betdetails: {

  },
  textBoxBet: {
    padding: "10px",
    background: theme.palette.background.brown,
    width: "120px", marginRight: "10px",
  },
  whiteButton: {
    border: "1px solid #00FFAB",
    background: "#fff",
    boxShadow: " 0px 0px 11px rgba(0, 255, 171, 0.5)",
    color: "#000",
    fontSize: "17px",
    fontWeight: 700,
    width: "calc(100% - 71px)",
  },
  textField: {
    background: "#2B2C31", 
    border: "0.5px solid #153F69",
    borderRadius: "5px",
    "& label": {
      color: "#39AED0",
    },
    "&  placeholder": {
      color: "#39AED0 !important",
    },
  },
}));

export default function (props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Box className={classes.betBox}>
      <Box mt={1} className={`${classes.betdetails} justifyBetween`}>
        <Typography variant="body2" color="primary">Cape Verde - Uganda</Typography>
        <Typography variant="h5" color="primary">4.37</Typography>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}><TextField
            label="Wager"
            placeholder="0"
            id="inputID"
            fullWidth
            className={clsx(classes.textField)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="filled"
          /></Grid>
          <Grid item xs={6}><TextField
            label="To Win"
            placeholder="0"
            id="inputID"
            fullWidth
            className={clsx(classes.textField)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="filled"
          /></Grid>
        </Grid>

      </Box>
      <Box textAlign="center" my={2}>
        <Typography variant="body2" color="primary">Odds have increased for your section</Typography>

      </Box>
      <Box mt={1} className={`${classes.betdetails} justifyBetween`}>
        <Typography variant="body2" color="primary">Cape Verde - Uganda</Typography>
        <Switch
          checked={state.checkedA}
          onChange={handleChange}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box>
      <Box mt={2} align="center">
        <Button variant="contained" size="large" fullWidth className={classes.whiteButton}>Place Parley Bet</Button>
      </Box>

    </Box>
  );
}
